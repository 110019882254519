<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/konst.jpg')"
      class="white--text"
      gradient="to right, rgba(80, 80, 80, .8), rgba(150, 150, 150, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="VAD VI GÖR" />

          <base-body>
            Johker Scenkonst fokuserar på att erbjuda dig tre saker inom scenkonsten; teater<b>produktioner</b>, <b>skådespelare</b> samt teater<b>manus</b>.
          </base-body>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
